import './MessageGroupPage.css';
import React from "react";
import { useParams } from 'react-router-dom';

import DesktopNavigation  from 'components/DesktopNavigation';
import MessageGroupFeed from 'components/MessageGroupFeed';
import MessagesFeed from 'components/MessageFeed';
import MessagesForm from 'components/MessageForm';

import {get} from 'lib/Requests';
import {checkAuth} from 'lib/CheckAuth';

export default function MessageGroupPage() {
  const [otherUser, setOtherUser] = React.useState([]);
  const [messageGroups, setMessageGroups] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [popped, setPopped] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const dataFetchedRef = React.useRef(false);
  const params = useParams();

  const loadUserShortData = async () => {
  //   try {
  //     const backend_url = `${process.env.REACT_APP_BACKEND_URL}/api/users/${params.handle}/short`
  //     const res = await fetch(backend_url, {
  //       method: "GET"
  //     });
  //     let resJson = await res.json();
  //     if (res.status === 200) {
  //       console.log('other user:',resJson)
  //       setOtherUser(resJson)
  //     } else {
  //       console.log(res)
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };  
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/@${params.handle}/short`
  get(url,{
    auth: true,
    success: function(data){
      console.log('other user:',data)
      setOtherUser(data)
    }
    })
  }

  const loadMessageGroupsData = async () => {
    // try {
    //   const backend_url = `${process.env.REACT_APP_BACKEND_URL}/api/message_groups`
    //   await getAccessToken()
    //   const access_token = localStorage.getItem("access_token")
    //   const res = await fetch(backend_url, {
    //     headers: {
    //       Authorization: `Bearer ${access_token}`
    //     },
    //     method: "GET"
    //   });
    //   let resJson = await res.json();
    //   if (res.status === 200) {
    //     setMessageGroups(resJson)
    //   } else {
    //     console.log(res)
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/message_groups`
    get(url,{
      auth: true,
      success: function(data){
        setMessageGroups(data)
      }
    })
  };  

  React.useEffect(()=>{
    //prevents double call
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    loadMessageGroupsData();
    loadUserShortData();
    checkAuth(setUser);
  }, [])
  return (
    <article>
      <DesktopNavigation user={user} active={'home'} setPopped={setPopped} />
      <section className='message_groups'>
        <MessageGroupFeed otherUser={otherUser} message_groups={messageGroups} />
      </section>
      <div className='content messages'>
        <MessagesFeed messages={messages} />
        <MessagesForm setMessages={setMessages} />
      </div>
    </article>
  );
}