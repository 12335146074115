import './ActivityContent.css';

import { Link } from "react-router-dom";
// import { DateTime } from 'luxon';
import { format_datetime, time_ago, time_future } from '../lib/DateTimeFormats';
import {ReactComponent as BombIcon} from './svg/bomb.svg';

export default function ActivityContent(props) {
  // const format_time_created_at = (value) => {
  //   // format: 2050-11-20 18:32:47 +0000
  //   const past = DateTime.fromISO(value)
  //   const now     = DateTime.now()
  //   const diff_mins = now.diff(past, 'minutes').toObject().minutes;
  //   const diff_hours = now.diff(past, 'hours').toObject().hours;

  //   if (diff_hours > 24.0){
  //     return past.toFormat("LLL L");
  //   } else if (diff_hours < 24.0 && diff_hours > 1.0) {
  //     return `${Math.floor(diff_hours)}h ago`;
  //   } else if (diff_hours < 1.0) {
  //     return `${Math.round(diff_mins)}m ago`;
  //   }
  // };

  // const format_time_expires_at = (value) => {
  //   // format: 2050-11-20 18:32:47 +0000
  //   const future = DateTime.fromISO(value)
  //   const now     = DateTime.now()
  //   const diff_mins = future.diff(now, 'minutes').toObject().minutes;
  //   const diff_hours = future.diff(now, 'hours').toObject().hours;
  //   const diff_days = future.diff(now, 'days').toObject().days;

  //   if (diff_hours > 24.0){
  //     return `${Math.floor(diff_days)}d`;
  //   } else if (diff_hours < 24.0 && diff_hours > 1.0) {
  //     return `${Math.floor(diff_hours)}h`;
  //   } else if (diff_hours < 1.0) {
  //     return `${Math.round(diff_mins)}m`;
  //   }
  // };

  let expires_at;
  if (props.activity.expires_at) {
    expires_at =  <div className="expires_at" title={format_datetime(props.activity.expires_at)}>
                    <BombIcon className='icon' />
                    <span className='ago'>{time_future(props.activity.expires_at)}</span>
                  </div>

  }

  return (
    <div className='activity_content_wrap'>
      <Link className='activity_avatar'to={`/@`+props.activity.handle} ></Link>
      <div className='activity_content'>
        <div className='activity_meta'>
        <div className='activity_identity' >
            <Link className='display_name' to={`/@`+props.activity.handle}>{props.activity.display_name}</Link>
            <Link className="handle" to={`/@`+props.activity.handle}>@{props.activity.handle}</Link>
          </div>{/* activity_identity */}
          <div className='activity_times'>
            <div className="created_at" title={format_datetime(props.activity.created_at)}>
              <span className='ago'>{time_ago(props.activity.created_at)}</span>  
            </div>
            {expires_at}
          </div>{/* activity_times */}
        </div>{/* activity_meta */}
        <div className="message">{props.activity.message}</div>
      </div>{/* activity_content */}
    </div>
  );
}